import React from "react";

export const travauxCardsData = [
  {
    id: "c1",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Eclairage de",
    titleWeak: "l’espace public",
    description:
      "Immobilière 3F souhaite revoir l’éclairage de votre résidence. A vous de choisir deux emplacements qui vous semblent prioritaires.",
    thumbnail: "c1_q1rep1.png",
    imgSrc: "c1_q1rep1.png",
    planMasse: "PlanMasse_C1.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title:
        "Seriez-vous prêt à vous impliquer dans la création ou l’entretien d’un jardin partagé ?",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "En priorité, sur quel type d’espace public faut-il accentuer l’éclairage ?",
        options: [
          {
            label: "Les cheminements",
            value: "q1rep1",
            results: 41,
          },
          {
            label: "Les aires de jeux",
            value: "q1rep2",
            results: 2,
          },
          {
            label: "L'espace central",
            value: "q1rep3",
            results: 10,
          },
          {
            label: "Les pieds d’immeubles",
            value: "q1rep4",
            results: 32,
          },
          {
            label: "Les zones de stationnement",
            value: "q1rep5",
            results: 21,
          },
        ],
        value: "",
        winner: "q1rep1",
        totResults: 106,
      },
    ],
    comments: [
      "Aires de jeux",
      "et les zones de stationnement, les cheminements",
      "Tous sauf les aires de jeu. Les nuisances sonores la journée sont déjà pénibles , alors si c'est éclairé le soir, bonjour!",
      "Éclairer tous les espaces ",
      "Petit étang ",
    ],
    comment: "",
  },
  {
    id: "c2",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "La privatisation",
    titleWeak: "des parking à l’étude",
    description:
      "Immobilière 3f souhaite étudier la possibilité de privatiser les emplacements de stationnements qui seront équipés de stop park de qualité avec verrou intégré.",
    thumbnail: "c2_q1rep2.png",
    imgSrc: "c2_q1rep2.png",
    planMasse: "PlanMasse_C2.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "Si la privatisation est possible, seriez-vous prêt à louer une place à 10 euros/mois ?",
        options: [
          {
            label: "Non",
            value: "q1rep1",
            results: 38,
          },
          {
            label: "Oui",
            value: "q1rep2",
            results: 69,
          },
        ],
        value: "",
        winner: "q1rep2",
        totResults: 107,
      },
    ],
    comments: [
      "Mal agencée ",
      "REFAIRE L'INTÉRIEUR TROP HUMIDITÉ MOISISSURES ET INSALUBRE ",
      "Place handicapé devant mon habitat si possible.",
      "Pourquoi des emplacements pour vélos sont inexistant ? Surtout en cette période ou l'ont favorise les déplacements en vélo.",
    ],
    comment: "",
  },
  {
    id: "c3",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "L’aménagement de",
    titleWeak: "l’espace central",
    description:
      "Le paysagiste prévoit de repenser l’espace central de votre résidence. Cet espace est au cœur de votre résidence. Aidez-nous à rendre cet espace agréable.",
    thumbnail: "c3_q1rep3_q2rep2.png",
    imgSrc: "c3_q1rep3_q2rep2.png",
    planMasse: "PlanMasse_C3.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "Parmi ces 3 types d’usages de l’espace commun de votre résidence, lequel préférez-vous ?",
        options: [
          {
            label:
              "Espace de convivialité pour les jeunes: des conteneurs ouverts avec agrès et atelier tag pour décorer le conteneur par des artistes du département.",
            value: "q1rep1",
            results: 20,
          },
          {
            label:
              "Espace de convivialité pour les séniors: des bancs au soleil l’hiver et à l’ombre l’été. Des espaces de jeux ( tables d’échecs, agrès sportifs)",
            value: "q1rep2",
            results: 33,
          },
          {
            label:
              "Espace de convivialité multigénérationnel : Un mix des deux précedentes options. Les deux espaces seront éloignés et séparés.",
            value: "q1rep3",
            results: 52,
          },
        ],
        value: "q1rep1",
        winner: "q1rep3",
        totResults: 105,
      },
      {
        id: "q2",
        type: "radio",
        title:
          "Souhaitez vous l’aménagement d’un barbecue au sein de l’espace central ?",
        options: [
          {
            label: "Oui",
            value: "q2rep1",
            results: 49,
          },
          {
            label: "Non",
            value: "q2rep2",
            results: 56,
          },
        ],
        value: "q2rep1",
        winner: "q2rep2",
        totResults: 105,
      },
    ],
    comments: [
      "Un barbecue ? Pour que ce soit squatter par les jeunes qui , soit dit en passant, font déjà des barbecue! Y implanter une aire de jeux pour les enfants  en lieu et place de celle qui se trouve sous les fenêtres des residents ( Nuisance sonore tout au long de l'année)",
      "METTRE DES BANCS POUR S'ASSEOIR ",
    ],
    comment: "",
  },
  {
    id: "c4",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Rénovation",
    titleWeak: "de la petite aire de jeux",
    description:
      "Cette aire de jeux est pour le moment peu fréquentée. Le paysagiste prévoit de repenser cet espace. Aidez-nous à lui trouver un nouvel usage.",
    thumbnail: "c4_q1rep1.png",
    imgSrc: "c4_q1rep1.png",
    planMasse: "PlanMasse_C4.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title: "Parmi ces 3 types d’usages, lequel préférez-vous ?",
        options: [
          {
            label: "Une aire de jeux améliorée",
            value: "q1rep1",
            results: 55,
          },
          {
            label: "Différents types de jardins (jardin sauvage, ... )",
            value: "q1rep2",
            results: 42,
          },
          {
            label: "Un terrain de pétanque",
            value: "q1rep3",
            results: 9,
          },
        ],
        value: "",
        winner: "q1rep1",
        totResults: 106,
      },
    ],
    comments: [],
    comment: "",
  },
  {
    id: "c5",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Votre projet",
    titleWeak: "pour la roseraie",
    description:
      "L’ancienne roseraie est un patrimoine de votre résidence qui est pour le moment peu utilisée. Aidez -nous à lui trouver une deuxième jeunesse !",
    thumbnail: "c5_q1rep3.png",
    imgSrc: "c5_q1rep3.png",
    planMasse: "PlanMasse_C5.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title: "Quel serait le meilleur projet pour la roseraie ?",
        options: [
          {
            label: "Des roses, une glycine, une vigne",
            value: "q1rep1",
            results: 33,
          },
          {
            label:
              "Un espace à vocation contemplative (expo photos, découverte à destination des écoles...)",
            value: "q1rep2",
            results: 17,
          },
          {
            label: "Un mix des deux précedentes réponses",
            value: "q1rep3",
            results: 56,
          },
        ],
        value: "",
        winner: "q1rep3",
        totResults: 106,
      },
    ],
    comments: ["Espace sportifs", "Potagé communautaire "],
    comment: "",
  },
  {
    id: "c6",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Accueillir la biodiversité",
    titleWeak: "de la nature",
    description:
      "Votre résidence comprend une grande diversité d’espèces végétales et animales. Immobilière 3F souhaire l’améliorer afin de vous offrir un cadre de vie meilleur et un lieu pour vous ressourcez, vous promenez et enfin vous émerveillez.",
    thumbnail: "c6_q1rep4.png",
    imgSrc: "c6_q1rep4.png",
    planMasse: "PlanMasse_C6.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title: "Parmi ces choix lequel préférez-vous ?",
        options: [
          {
            label:
              "Des nichoirs à oiseaux attire et aide les oiseaux à se maintenir sur le site et vous offre une ambiance appréciée. Ce sont également des alliés pour réduire les insectes nuisibles.",
            value: "q1rep1",
            results: 32,
          },
          {
            label:
              "Des gîtes à herisson très utile dans les jardins et potagers car ils consomment une grande quantité d’invertébrés ( escargots, limaces, scarabées…)",
            value: "q1rep2",
            results: 7,
          },
          {
            label:
              "Des gîtes à insectes permettent la pollinisation des plantes, processus indispensable pour la production de fruits et légumes et éliminent des espèces ravageurs ( pucerons, vers blanc…) Ils sont donc une alternative à l’utilisation de pesticides.",
            value: "q1rep3",
            results: 5,
          },
          {
            label: "Les trois choix",
            value: "q1rep4",
            results: 54,
          },
        ],
        value: "",
        winner: "q1rep4",
        totResults: 98,
      },
    ],
    comments: [],
    comment: "",
  },
  {
    id: "c7",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Plantation nouvelles",
    titleWeak: "d’arbres, de fleurs et de plantes",
    description:
      "Pour embellir davantage votre résidence, nous avons prévu une grande diversité botanique. Aussi, si vous le souhaitez, nous pouvons planter des arbres fruitiers. Ils permettent Les arbres fruitiers permettent un accès à une nourriture saine et gratuite pendant la saison de cueillette. En revanche, ils nécessitent un entretien pendant cette période pour éviter les risques de salissure des fruits mûrs. Aidez-nous à choisir les plantes qui vous conviennent le plus.",
    thumbnail: "c7_q1rep1.png",
    imgSrc: "c7_q1rep1.png",
    planMasse: "PlanMasse_C7.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "Quel type de plantes souhaitez-vous au pied de votre immeuble ?",
        options: [
          {
            label: "Des fleurs",
            value: "q1rep1",
            results: 28,
          },
          {
            label: "Des plantes comestibles",
            value: "q1rep2",
            results: 3,
          },
          {
            label:
              "Des plantes persistantes qui gardent leurs feuillages même en hiver",
            value: "q1rep3",
            results: 33,
          },
          {
            label: "Des arbres fruitiers",
            value: "q1rep4",
            results: 13,
          },
          {
            label: "Un mix de toutes les réponses",
            value: "q1rep5",
            results: 27,
          },
        ],
        value: "",
        winner: "q1rep1",
        totResults: 104,
      },
    ],
    comments: [
      "Rien du tout ",
      "Plantation nouvelles ",
      "RETIRER LE SAC BLANC AUCUN UTILITÉ ET SALISSANT ",
    ],
    comment: "",
  },
  {
    id: "nc1",
    type: "nonConcertable",
    layout: "nonConcertableLayoutOne",
    titleStrong: "La restauration",
    titleWeak: "des espaces verts",
    description:
      "Votre résidence comprend une grande quantité d’arbres, ils contribuent quotidiennement à notre santé, en améliorant la qualité de l’air.Un grand arbre produit en période de végétation la quantité d'oxygène respirée par quatre personnes. Le projet développe l’entretien des arbres par la taille et/ou l’abattage des arbres malades. Pour votre sécurité, certains arbres abîmés seront abattus, d’autres seront taillés. Les améliorations permettront de nouveler des coins d'ombre pour l’été, des coins plus éclairés pour  diversifier la végétation et d’autres endroits plus dégagés pour profiter du soleil à la mi-saison. Enfin, la taille des arbres, selon nécessité, rendra plus de lumière aux logements. ",
    jsxDescription: (
      <p>
        <strong>Votre résidence comprend une grande quantité d’arbres</strong>,
        ils contribuent quotidiennement à notre santé, en améliorant la qualité
        de l’air.Un grand arbre produit en période de végétation la quantité
        d'oxygène respirée par quatre personnes. Le projet développe l’entretien
        des arbres par la taille et/ou l’abattage des arbres malades. Pour votre
        sécurité, <strong>certains arbres abîmés seront abattus</strong>,
        d’autres seront taillés. Les améliorations permettront de nouveler des
        coins d'ombre pour l’été, des coins plus éclairés pour diversifier la
        végétation et d’autres endroits plus dégagés pour profiter du soleil à
        la mi-saison. Enfin, la taille des arbres, selon nécessité, rendra plus
        de lumière aux logements.
        <p style={{ marginTop: "1.5em" }}>
          La restauration <strong>des pelouses de votre résidence :</strong> Ces
          travaux mettront en valeur les espaces verts et developperont des
          habitats privilégiés pour la faune et la flore locale et lutteront
          contre les espèces invasives.
        </p>
        <p style={{ marginTop: "1.5em", fontWeight: "bold" }}>
          Attention ! Pendant la durée des travaux d’abattage des arbres
          concernés, il est important de suivre les consignes de sécurité.
        </p>
      </p>
    ),
    jsxNote: <React.Fragment></React.Fragment>,
    detailsTravaux: [],
    thumbnail: "NC1_thumb2.png",
    video: {
      url: "https://youtu.be/YrdKOVTZdG8",
      width: 300,
      height: 400,
    },
    planMasse: "PlanMasse_NC1.png",
    tags: ["informative"],
    submitted: false,
    questions: {},
    comment: "",
    comments: [
      "Je ne sais pas ",
      "tres bonne idée mes surtout enlever les arbres qui sont derrière chez moi car quand il y a du vent sa tape dans les fenêtres ",
      "Mettre des barrières pour empecher les scooters, mini motos et les voitures comme la clio, la smart et autres d'accéder à la pelouse. ",
      "ok",
      "Mettre des bornes à bip dans chaque accès ",
      "Je suis d'accord ",
      "Eviter les arbres trop près des immeubles contre les fenêtres des RDC",
      "Garder les arbres autour du petit étang ça évite de voir les bâtiments  en face ",
      "R.A.S ",
    ],
  },
  {
    id: "c8",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Un potager partagé",
    titleWeak: "pour la résidence",
    description:
      "Un projet de jardins partagés est prévu dans votre résidence. Votre bailleur a prévu de faire appel à une association pour vous accompagner dans ce projet.",
    thumbnail: "c8_q1rep1_q2rep1.png",
    imgSrc: "c8_q1rep1_q2rep1.png",
    planMasse: "PlanMasse_C8.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title:
        "Seriez-vous prêt à vous impliquer dans la création ou l’entretien d’un jardin partagé ?",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: true,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title: "Quel type de jardin partagé convient le plus à vos attentes ?",
        options: [
          {
            label: "Individuel",
            value: "q1rep1",
            results: 81,
          },
          {
            label: "Collectif",
            value: "q1rep2",
            results: 15,
          },
        ],
        value: "q1rep1",
        winner: "q1rep1",
        totResults: 96,
      },
      {
        id: "q2",
        type: "radio",
        title: "Quel forme pour la culture ?",
        options: [
          {
            label: "Culture au sol",
            value: "q2rep1",
            results: 66,
          },
          {
            label: "Carré potager",
            value: "q2rep2",
            results: 10,
          },
          {
            label: "Bac surelevé",
            value: "q2rep3",
            results: 20,
          },
        ],
        value: "q2rep1",
        winner: "q2rep1",
        totResults: 96,
      },
    ],
    comments: ["privé avec une porte "],
    comment: "",
  },
  {
    id: "c9",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Les ateliers",
    titleWeak: "nature",
    description:
      "Immobilière 3F souhaite développer l'écologie familiale au sein de la résidence. Pour cela, nous envisageons mettre en place des ateliers nature dans lequel vous, vos enfants et petits-enfants pourront exercer leur main verte !",
    thumbnail: "c9_q1rep5.png",
    imgSrc: "c9_q1rep5.png",
    planMasse: "PlanMasse_C9.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title: "Lequel de ces ateliers correspond le plus à vos attentes ?",
        options: [
          {
            label: "Ateliers plantations",
            value: "q1rep1",
            results: 27,
          },
          {
            label: "Atelier compostage ( avec le responsable du compost)",
            value: "q1rep2",
            results: 10,
          },
          {
            label: "Atelier fabrication de gîtes et de nichoirs",
            value: "q1rep3",
            results: 10,
          },
          {
            label: "Atelier observations in situ",
            value: "q1rep4",
            results: 3,
          },
          {
            label: "Je ne suis pas intéressé(e)",
            value: "q1rep5",
            results: 47,
          },
        ],
        value: "",
        winner: "q1rep5",
        totResults: 97,
      },
    ],
    comments: [
      "Cela serait intéressant mais je n'aurais pas le temps avec mes activités. ",
      "Je ne suis pas intéressé par cette idée",
      "Pour finir, après lecture complète  de votre enquête, on voit bien que les architectes et autres décideurs ne vivent pas dans des cités hlm. Tranquillité et sécurité sont, à mon sens , les deux maître -mots d'un tel projet. Avez-vous entendu parler de La Plage Bleue dans notre commune ? Pas besoin de plus de nuisance au sein de la cité avec des aires de jeux , des barbecues et autres.",
    ],
    comment: "",
  },
  {
    id: "nc2",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Amélioration écologique",
    titleWeak: "du petit étang",
    description:
      "L’étang qui donne son nom à votre résidence constitue une réserve de nature et une zone calme qui accueille de nombreuses espèces animales et végétales. Néanmoins, le plan d’eau est vieillissant, et la végétation a besoin de se regénérer. Vous l’aurez compris, une amélioration et un réaménagement de cet espace sont donc nécessaires !      ",
    jsxDescription: (
      <React.Fragment>
        <p>
          L’étang qui donne son nom à votre résidence constitue une réserve de
          nature et une zone calme qui accueille de nombreuses espèces animales
          et végétales. Néanmoins, le plan d’eau est vieillissant, et la
          végétation a besoin de se regénérer. Vous l’aurez compris, une
          amélioration et un réaménagement de cet espace sont donc nécessaires !
        </p>
        <p>
          <strong>
            Plusieurs travaux sont prévus pour rendre à cet espace son caractère
            d’exception, rééquilibrer l’écosystème et enfin nous faire profiter
            de ses bienfaits.
          </strong>
        </p>
      </React.Fragment>
    ),
    jsxNote: (
      <p>
        L’étang restera clos et interdit au public afin de préserver son
        équilibre écologique. Cependant, des visites accompagnés pourront être
        organisé par Immobilière 3F sous forme de chantier nature pendant les
        travaux de réaménagement ou de visites pédagogiques guidées pour tous
        (après travaux).
      </p>
    ),
    detailsTravaux: [
      {
        id: 1,
        emoji: "🍃",
        detail: "Préserver l’existant (tulipes, lierres, arbres…)",
      },
      {
        id: 2,
        emoji: "💦",
        detail:
          "Eclaircir et éradiquer les espèces envahissantes (bambou, aucuba, buddleia… )",
      },
      {
        id: 3,
        emoji: "🐝",
        detail:
          "Attirer les pollinisateurs et offrir des zones de reproduction et des abris à la faune",
      },
      {
        id: 4,
        emoji: "💦",
        detail:
          "Retirer la vase de l’étang et réduire la quantité de lentilles d’eau à la surface de l’eau (dépôt important de feuilles mortes)",
      },
      {
        id: 5,
        emoji: "🍃",
        detail:
          "Améliorer les berges et la qualité de l’eau du bassin avec des plantes aimant l’eau (nénuphars) et le soleil ce qui permettra d’oxygéner l’eau",
      },
      {
        id: 6,
        emoji: "🏗️",
        detail: "Réparation du pont et sécurisation du passage.",
      },
      {
        id: 7,
        emoji: "🏗️",
        detail: "Construction d’un chemin en bois pour préserver la végétation",
      },
    ],
    thumbnail: "NC1_thumb2.png",
    video: {
      url: "https://youtu.be/IeU63_M1LVU",
      width: 300,
      height: 400,
    },
    planMasse: "PlanMasse_NC2.png",
    tags: ["informative"],
    submitted: false,
    questions: {},
    comment: "",
    comments: [
      "Je ne sais pas ",
      "Des bancs aux alentours de l’étang ",
      "ok je suis pour ",
      "Belle réhabilitation ",
      "ok",
      "Construire des fontaines ",
      "Je suis d accord ",
      "Intéressant",
      "non",
      "Zone ou les voyous passe pour cambrioler les RDC",
      "R.A.S",
    ],
  },
];
