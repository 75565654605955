import { db } from "../config/firebase";

// Load results from firebase
export const loadResults = ({ dispatch }) => {
  // TODO: switch from client to server
  // Utilisateurs
  // db.collection("locataires")
  //   .get()
  //   .then((snapshot) => {
  //     dispatch({ type: "SET_PARTICIPANTS", value: snapshot.size });
  //     let csv = "Nom,Mobile,Réference locataire,Date\n";
  //     snapshot.forEach((doc) => {
  //       csv += `${doc.data().fullName},${doc.data().numTel},${
  //         doc.data().refLoc
  //       },${doc.data().dateCreation}`;
  //       csv += "\n";
  //     });
  //     console.log(csv);
  //   })
  //   .catch((err) => console.log(err));
  // Resultats concertation
  // let cardsData = {
  //   c1: {
  //     comments: [],
  //     q1: {},
  //     q2: {},
  //     q3: {},
  //     q4: {},
  //   },
  //   c2: {
  //     comments: [],
  //     q1: {},
  //     q2: {},
  //     q3: {},
  //     q4: {},
  //   },
  //   c3: {
  //     comments: [],
  //     q1: {},
  //     q2: {},
  //     q3: {},
  //     q4: {},
  //   },
  //   c4: {
  //     comments: [],
  //     q1: {},
  //     q2: {},
  //     q3: {},
  //     q4: {},
  //   },
  //   c5: {
  //     comments: [],
  //     q1: {},
  //     q2: {},
  //     q3: {},
  //     q4: {},
  //   },
  //   c6: {
  //     comments: [],
  //     q1: {},
  //     q2: {},
  //     q3: {},
  //     q4: {},
  //   },
  //   c7: {
  //     comments: [],
  //     q1: {},
  //     q2: {},
  //     q3: {},
  //     q4: {},
  //   },
  //   c8: {
  //     comments: [],
  //     q1: {},
  //     q2: {},
  //     q3: {},
  //     q4: {},
  //   },
  //   c9: {
  //     comments: [],
  //     q1: {},
  //     q2: {},
  //     q3: {},
  //     q4: {},
  //   },
  //   nc1: {
  //     comments: [],
  //     q1: {},
  //     q2: {},
  //     q3: {},
  //     q4: {},
  //   },
  //   nc2: {
  //     comments: [],
  //     q1: {},
  //     q2: {},
  //     q3: {},
  //     q4: {},
  //   },
  // };
  // let questions = ["q1", "q2", "q3", "q4"];
  // let value = "";
  // db.collection("resultats")
  //   .get()
  //   .then((snapshot) => {
  //     dispatch({ type: "SET_REPONSES", value: snapshot.size });
  //     snapshot.forEach((doc) => {
  //       // Ajouter les commentaires
  //       if ("comment" in doc.data().answers) {
  //         if (doc.data().answers.comment !== "") {
  //           cardsData[doc.data().cardID].comments.push(
  //             doc.data().answers.comment
  //           );
  //         }
  //       }
  //       // Ajouter le nombre de réponses
  //       let value = "";
  //       questions.map((question) => {
  //         if (question in doc.data().answers) {
  //           value = doc.data().answers[question].value;
  //           console.log("[value]", value);
  //           if (
  //             typeof cardsData[doc.data().cardID][question][value] !==
  //             "undefined"
  //           ) {
  //             cardsData[doc.data().cardID][question][value] += 1;
  //           } else {
  //             cardsData[doc.data().cardID][question][value] = 1;
  //           }
  //         }
  //       });
  //     });
  //     console.log(cardsData);
  //   })
  //   .catch((err) => console.log(err));
  // Load emails
  // db.collection("messages")
  //   .get()
  //   .then((snapshot) => {
  //     dispatch({ type: "SET_PARTICIPANTS", value: snapshot.size });
  //     let csv = "email;message;date\n";
  //     snapshot.forEach((doc) => {
  //       csv += `${doc.data().email};${doc.data().message};${doc.data().date}`;
  //       csv += "\n";
  //     });
  //     console.log(csv);
  //   })
  //   .catch((err) => console.log(err));
};
