import React from "react";
import classes from "./IntroModal.module.css";
import { Button } from "@material-ui/core";

const IntroModal = (props) => {
  return (
    <div className={classes.IntroModal}>
      <div className={classes.IntroTitle}>
        <h3>
          Bienvenue sur Corehab, votre plateforme de personnalisation de travaux
          !
        </h3>
      </div>
      <div className={classes.IntroDescription}>
        <p style={{ marginTop: "1.5em" }}>
          L’interface se présente comme une liste de travaux. Dans cette liste,
          certains éléments sont{" "}
          <span className={classes.Personnalisable}>personnalisables</span>: Ils
          sont indiqués par des fiches en{" "}
          <span className={classes.Personnalisable}>bleu</span> sur l’interface.
          Les autres éléments sont simplement{" "}
          <span className={classes.Informative}>informatifs</span>: ils sont
          indiqués par des fiches en{" "}
          <span className={classes.Informative}>jaune</span> dans l’interface.
        </p>
        <p style={{ marginTop: "1.5em" }}>
          Pour <span className={classes.Personnalisable}>personnaliser</span>{" "}
          une fiche <span className={classes.Personnalisable}>bleue</span>{" "}
          cliquez simplement dessus et répondez aux questions. Une fois que vous
          avez répondu aux questions de la fiches et cliquez sur “valider”, un
          ☑️ apparaîtra sur la fiche confirmant que vous l’avez remplie.
        </p>
        <p style={{ marginTop: "1.5em" }}>
          Renseignez toutes les fiches proposées sur la plateforme, cliquez sur
          le bouton{" "}
          <span>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              style={{ margin: "0 .5em" }}
            >
              Valider
            </Button>
          </span>{" "}
          et vos réponses seront enregistrées et transmises à nos équipes.
        </p>
      </div>
      <div className={classes.IntroButton}>
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          style={{ width: "100%" }}
          onClick={props.clicked}
        >
          Commencer
        </Button>
      </div>
    </div>
  );
};

export default IntroModal;
