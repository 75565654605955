export const TravauxReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_ANSWER":
      return {
        ...state,
        cards: state.cards.map((card, index) =>
          card.id === action.cardID
            ? {
                ...card,
                questions: card.questions.map((question, index) =>
                  question.id === action.questionID
                    ? {
                        ...question,
                        value: action.answer,
                      }
                    : question
                ),
              }
            : card
        ),
      };

    case "UPDATE_IMAGE":
      // First we get card's ID
      let cardID = action.cardID;
      // Then the questions (optional)
      let questions =
        state.cards[state.cards.findIndex((card) => card.id === cardID)]
          .questions;
      // An answers array with the first element being the question's ID
      let answers = [cardID];
      Object.keys(questions)
        .sort()
        .map((question) => answers.push(questions[question].value));
      // Build imageURL from answers
      let imageURL = answers.join("_");
      // We update and return the state
      return {
        ...state,
        cards: state.cards.map((card, index) =>
          card.id === cardID
            ? {
                ...card,
                imgSrc: imageURL + ".png",
              }
            : card
        ),
      };

    case "SET_CONDITIONAL":
      return {
        ...state,
        cards: state.cards.map((card, index) =>
          card.id === action.cardID
            ? {
                ...card,
                conditional: {
                  ...card.conditional,
                  value: action.value,
                },
              }
            : card
        ),
      };

    case "SET_COMMENT":
      return {
        ...state,
        cards: state.cards.map((card, index) =>
          card.id === action.cardID
            ? {
                ...card,
                comment: action.comment,
              }
            : card
        ),
      };

    case "SET_SUBMITTED":
      return {
        ...state,
        cards: state.cards.map((card, index) =>
          card.id === action.cardID
            ? {
                ...card,
                submitted: true,
              }
            : card
        ),
      };

    case "SET_SELECTEDCARD":
      return {
        ...state,
        selectedCard: action.selectedCard,
      };

    case "SET_FILTERS":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.id]: {
            id: action.id,
            value: action.value,
          },
        },
      };

    case "SET_SHOWCARD":
      return {
        ...state,
        showCard: action.show,
      };

    case "SET_HIDECARD":
      return {
        ...state,
        showCard: action.show,
        selectedCard: "",
      };

    case "LOAD_ANSWERS_C":
      return {
        ...state,
        cards: state.cards.map((card, index) =>
          card.id === action.cardID
            ? {
                ...card,
                submitted: true,
                comment: action.comment,
                questions: card.questions.map((question, index) =>
                  question.id === action.questionID
                    ? {
                        ...question,
                        value: action.value,
                      }
                    : question
                ),
              }
            : card
        ),
      };

    case "LOAD_ANSWERS_NC":
      return {
        ...state,
        cards: state.cards.map((card, index) =>
          card.id === action.cardID
            ? {
                ...card,
                submitted: true,
                comment: action.comment,
              }
            : card
        ),
      };

    default:
      return state;
  }
};
