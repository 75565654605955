import React from "react";
import classes from "./CircularProgressBar.module.css";

const CircularProgressBar = (props) => {
  const CircularSteps = [
    null,
    "polygon(50% 50%, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0)",
    "polygon(50% 50%, 50% 0, 100% 0, 100% 0, 100% 0, 100% 0, 100% 0)",
    "polygon(50% 50%, 50% 0, 100% 0, 100% 100%, 100% 100%, 100% 100%, 100% 100%)",
    "polygon(50% 50%, 50% 0, 100% 0, 100% 100%, 0 100%, 0 100%, 0 100%)",
    "polygon(50% 50%, 50% 0, 100% 0, 100% 100%, 0 100%, 0 0, 0 0)",
    "polygon(50% 50%, 50% 0, 100% 0, 100% 100%, 0 100%, 0 0, 50% 0)",
    "polygon(50% 50%, 50% 0, 100% 0, 100% 100%, 0 100%, 0 0, 50% 0)",
  ];
  return (
    <React.Fragment>
      <div
        className={classes.Circle}
        style={{ clipPath: CircularSteps[props.etape] }}
      ></div>
      <div className={classes.Etape}>{props.etape}</div>
    </React.Fragment>
  );
};

export default CircularProgressBar;
