import { db } from "../config/firebase";
import moment from "moment";

export const uploadMessage = (email, message, dispatch) => {
  let messageDetails = {
    email,
    message,
    date: moment().format(),
  };

  db.collection("messages")
    .doc(email + "+" + moment().format())
    .set(messageDetails)
    .then((resp) => {
      console.log(resp);
      dispatch({
        type: "MESSAGE_SENT",
        helperType: "success",
        helperMessage: "Merci ! Votre message a bien été envoyé.",
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: "SET_ERROR",
        helperType: "error",
        helperMessage:
          "Une erreur s'est produite lors de l'envoi de votre message.",
      });
    });
};
