import React, { useState } from "react";
import classes from "./Footer.module.css";
// Components
import Modal from "../../UI/Modal/Modal";
import CGU from "../../Information/CGU/CGU";
import PDC from "../../Information/PDC/PDC";

const Footer = () => {
  const [showCGU, setShowCGU] = useState(false);
  const [showPDC, setShowPDC] = useState(false);

  return (
    <div className={classes.Footer}>
      <Modal show={showCGU} clicked={() => setShowCGU(false)}>
        <CGU />
      </Modal>
      <Modal show={showPDC} clicked={() => setShowPDC(false)}>
        <PDC />
      </Modal>
      <div className={classes.LogoContainer}>
        <img
          src={require("../../../assets/homePage/i3f-logo.png")}
          alt="I3F Logo"
        />
      </div>

      <div
        style={{ width: "80vw", height: "0.1em", background: "white" }}
      ></div>
      <div className={classes.LegalLinks}>
        <p onClick={() => setShowCGU(true)}>CGU</p>
        <p onClick={() => setShowPDC(true)}>Politique de confidentialité</p>
      </div>
      <div className={classes.Copyrights}>
        <p>
          <span>Corehab</span>, 1001rues, 2016-2020, tous droits réservés
        </p>
      </div>
    </div>
  );
};

export default Footer;
