import React from "react";
import classes from "./PageOne.module.css";
// Images
// import BonHomme from "../../assets/images/bonhomme.png";
// Primary Components
// import DescriptionSection from "../../components/HomePage/DescriptionSection/DescriptionSection";
import ContactForm from "../../../components/Travaux/ContactForm/ContactForm";
import Footer from "../../../components/Travaux/Footer/Footer";
// UI Componentns
import Separator from "../../../components/UI/Separator/Separator";
// import LongButton from "../../components/UI/Buttons/LongButton/LongButton";
// Layout
import Layout from "../../../hoc/Layout/Layout";

const PageOne = (props) => {
  const tegeval = require(`../../../assets/homePage/tegeval.jpg`);
  const pont = require(`../../../assets/homePage/pont.png`);
  const tulipes3 = require(`../../../assets/homePage/tulipes3.png`);

  return (
    <Layout>
      {/* <div className={classes.HeaderImage}>
        <img style={{ width: "100%" }} alt="header" src={illustrationAttente} />
      </div> */}
      <div className={classes.HeroImage}>
        <img alt="Planning" src={pont} />
      </div>

      <div className={classes.Section}>
        <div className={classes.SectionTitle}>
          <h3>Un projet en concertation avec les locataires</h3>
        </div>

        <div className={classes.SectionSubtitle}>
          <p>
            Située dans le sud-est de la région parisienne, la commune de
            Valenton offre un cadre de vie urbain fourni en parcs et jardins où
            l’eau tient une place de choix. Des zones humides communiquent en
            effet avec la Seine toute proche, comme les roselières du parc de la
            Rose bleue. Les habitants de Valenton bénéficient ainsi en moyenne
            de 80 m2 d’espaces verts, soit dix fois la moyenne nationale !
          </p>
        </div>

        <div className={classes.EmojiText} style={{ marginBottom: "1.5em" }}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            📰
          </span>
          <p>
            <strong>Le constat:</strong> La municipalité de Valenton est engagée
            depuis longtemps dans la promotion de la biodiversité. A la suite
            d’un diagnostic complet effectué en 2013, elle revendique des
            résultats positifs avec le retour de certaines espèces ordinaires
            mais en déclin comme les moineaux et les hirondelles. Titulaire de 2
            fleurs en tant que « Ville fleurie », elle est engagée dans une
            démarche zéro phyto, c’est-à-dire qu’elle ne fait pas usage de
            pesticides ou d’engrais chimiques pour entretenir les espaces verts.
          </p>
        </div>

        <div className={classes.EmojiText} style={{ marginBottom: "1.5em" }}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>
            <strong>Proximité:</strong> En plus de la coulée verte Tégéval, la
            résidence profite quant à elle d’un contact immédiat avec les parcs
            de Limeil et Jacques Duclos ainsi qu’avec le château de Valenton qui
            sont accessibles à moins de 3 minutes de marche. Dans un rayon d’un
            kilomètre se trouvent aussi plusieurs espaces de nature remarquable
            : les bois de la Grande, Notre-Dame et Grosbois ou la friche du
            Grand Wirtemberg.
          </p>
        </div>

        <div
          className={classes.Illustration}
          style={{
            backgroundImage: `url(${tegeval})`,
          }}
        ></div>
        <p style={{ textAlign: "center", marginBottom: "2em" }}>
          La coulée verte Tégéval
        </p>

        <div className={classes.EmojiText} style={{ marginBottom: "1.5em" }}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            🌲
          </span>
          <p>
            <strong> La biodiversité au sein de la résidence :</strong> la zone
            de sous-bois entourant l’étang est particulièrement riche et
            accueillante. À la bonne saison, on peut y voir la floraison
            spectaculaire de la tulipe des bois, qui est une espèce protégée en
            France. Le plan d’eau lui-même est aujourd’hui fortement dégradé par
            les dépôts de feuilles dont témoigne sa colonisation par les
            lentilles d’eau. Le parc arboré compte pour sa part 28 essences,
            dont une majorité d’érables et de tilleuls accueillant de nombreux
            oiseaux communs. On signale cependant quelques espèces ayant un
            potentiel invasif tels que la perruche et le bambou.
          </p>
        </div>

        <div
          className={classes.Illustration}
          style={{
            backgroundImage: `url(${tulipes3})`,
            backgroundPosition: "right",
          }}
        ></div>
        <p style={{ textAlign: "center", marginBottom: "2em" }}>
          Des tulipes sauvages au bord de l’étang
        </p>
      </div>

      <div className={classes.Footer}>
        <Footer />
      </div>
    </Layout>
  );
};

export default PageOne;
