import React from "react";
import classes from "./ResultsNonConcertableLayout.module.css";
// MUI
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
// import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import { IconButton } from "@material-ui/core";
import { TextField, Button } from "@material-ui/core";
import Separator from "../../../UI/Separator/Separator";
// Context
import { AuthContext } from "../../../../contexts/AuthContext";
// Utils
import { uploadAnswer } from "../../../../utils/TravauxUtils";
// Youtube
// import ReactYoutube from "../../../../utils/ReactYoutube";
import ReactPlayer from "react-player";

const ResultsNonConcertableLayout = ({
  card,
  dispatch,
  width,
  currentUser,
  setCommentsModalShow,
}) => {
  const handleSubmit = () => {
    let answers = {};

    answers["comment"] = card.comment;
    uploadAnswer(currentUser.uid, card.id, answers, dispatch);
  };

  // Header =============================================================================================
  const header = (
    <header className={classes.CardHeader}>
      <div className={classes.CardTitle}>
        <h3>{card.titleStrong}</h3>
        <h4>{card.titleWeak}</h4>
      </div>
      <div className={classes.CloseIcon}>
        <IconButton
          onClick={() => dispatch({ type: "SET_HIDECARD", show: false })}
        >
          <CancelRoundedIcon style={{ color: "black", fontSize: 30 }} />
        </IconButton>
      </div>
    </header>
  );

  // YOUTUBE VIDEO =====================================================================================
  const video = (
    <div className={classes.YoutubePlayer}>
      <ReactPlayer
        url={card.video.url}
        loop={true}
        width={card.video.width}
        height={card.video.height}
        playing={true}
      />
    </div>
  );

  // DESCRIPTION =======================================================================================
  const description = (
    <div className={classes.Description}>
      <div className={classes.DescriptionTitle}>
        <p>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            🦺
          </span>{" "}
          Nature des travaux :
        </p>
      </div>
      <div className={classes.DescriptionContent}>{card.jsxDescription}</div>
    </div>
  );

  // A NOTER ===========================================================================================
  const aNoter = (
    <div className={classes.Description} style={{ marginTop: "2em" }}>
      <div className={classes.DescriptionTitle}>
        <p>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            ⚠️
          </span>{" "}
          A noter :
        </p>
      </div>
      <div className={classes.DescriptionContent}>
        <p>{card.jsxNote}</p>
      </div>
    </div>
  );

  // PLAN MASSE ========================================================================================
  const planMasse = (
    <div className={classes.NCSection}>
      <div className={classes.DescriptionTitle}>
        <p>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            🧭
          </span>{" "}
          Localisation des travaux
        </p>
      </div>
      <p style={{ fontSize: "0.85em", marginTop: "0.8em" }}>
        {card.planMasseTexte}
      </p>

      <div className={classes.MapImage}>
        <img
          style={{ width: "100%" }}
          alt="map"
          src={require(`../../../../assets/travaux/${card.planMasse}`)}
        />
      </div>
    </div>
  );

  // LISTE DETAILS DES TRAVAUX ==========================================================================================
  const detailsTravaux = (
    <div className={classes.NCSection}>
      <div className={classes.DescriptionTitle}>
        <p>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            🔔
          </span>{" "}
          Les nuisance occasionnées
        </p>
      </div>

      {card.detailsTravaux.map((detail) => (
        <div className={classes.NCItem} key={detail.id}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            {detail.emoji}
          </span>{" "}
          <p>{detail.detail}</p>
        </div>
      ))}
    </div>
  );

  // Commentaires ===============================================================================================
  const commentaires = (
    <div className={classes.CommentSection}>
      <h3>Commentaires :</h3>
      {card.comments.map((comment, index) => {
        return (
          <div className={classes.Comment} key={index}>
            <h3>#{index + 1}</h3>
            <p>{comment}</p>
          </div>
        );
      })}
    </div>
  );

  // Commentaire + Valider  =============================================================================================
  const commentValider = (
    <div className={classes.Button}>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        style={{ width: "10em" }}
        onClick={() => {
          dispatch({ type: "SET_HIDECARD" });
        }}
        disabled={card.comment === "azert"}
      >
        Retour
      </Button>
    </div>
  );

  // calendrier
  const calendrier = (
    <div className={classes.NCSection}>
      <div className={classes.DescriptionTitle}>
        <p>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            📅
          </span>{" "}
          Le calendrier des travaux
        </p>
      </div>
      <div className={classes.NCItem}>
        <p>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            ⏳
          </span>{" "}
          Durée estimative des travaux: {card.duration}
        </p>
      </div>
      <div className={classes.NCItem}>
        <p>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            ⏲️
          </span>{" "}
          Période de travaux: {card.periode}
        </p>
      </div>
    </div>
  );

  // Layout ONE ==========================================================================================================
  const layoutOne =
    width < 1400 ? (
      <div className={classes.Card}>
        {header}
        {video}
        <div className={classes.CardContent}>
          {description}
          {planMasse}
          {/* {commentValider} */}
          {commentaires}
          {commentValider}
        </div>
      </div>
    ) : (
      <div className={classes.Card}>
        <div className={classes.LeftSection}>
          {header}
          {video}
          {planMasse}
        </div>
        <div className={classes.RightSection}>
          <div className={classes.CardContent}>
            {description}
            {/* {commentValider} */}
            {commentaires}
          </div>
        </div>
      </div>
    );

  // Layout TWO ==========================================================================================================
  const layoutTwo =
    width < 1400 ? (
      <div className={classes.Card}>
        {header}
        {video}
        <div className={classes.CardContent}>
          {description}
          {detailsTravaux}
          {planMasse}
          {calendrier}
          {commentaires}
          {commentValider}
        </div>
      </div>
    ) : (
      <div className={classes.Card}>
        <div className={classes.LeftSection}>
          {header}
          {video}
          {description}
        </div>
        <div className={classes.RightSection}>
          <div className={classes.CardContent}>
            {planMasse}
            {detailsTravaux}
            {calendrier}
            {commentaires}
          </div>
        </div>
      </div>
    );

  // RETURNED JSX ========================================================================================================
  if (card.layout === "nonConcertableLayoutOne") {
    return <React.Fragment>{layoutOne}</React.Fragment>;
  } else {
    return <React.Fragment>{layoutTwo}</React.Fragment>;
  }
};

export default ResultsNonConcertableLayout;
