import React from "react";
import classes from "./Blog.module.css";
// ICONS
// import { Icon } from "@iconify/react";
// import newspaperIcon from "@iconify/icons-ion/newspaper";
// Primary Components
import BlogCard from "../../components/HomePage/BlogCard/BlogCard";
// Images

const Blog = (props) => {
  return (
    <div className={classes.Blog}>
      <BlogCard blogPostHandler={props.blogPostHandler} />
    </div>
  );
};

export default Blog;
