import React from "react";
import classes from "./Toolbar.module.css";
// MUI
import FaceRoundedIcon from "@material-ui/icons/FaceRounded";
import { IconButton } from "@material-ui/core";
import { Button } from "@material-ui/core";
// Routing
import { withRouter } from "react-router-dom";

const Toolbar = (props) => {
  const participerHandler = () => {
    props.history.push("/resultats");
  };
  const homeButtonHandlet = () => {
    props.history.push("/");
  };
  return (
    <header className={classes.Toolbar}>
      <div className={classes.Logo} onClick={homeButtonHandlet}>
        <h3 style={{ fontFamily: "Futura Extra Bold" }}>Corehab</h3>
      </div>
      <div className={classes.Button}>
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          style={{ width: "7em" }}
          onClick={participerHandler}
        >
          Résultats
        </Button>
      </div>
    </header>
  );
};

export default withRouter(Toolbar);

// import React from "react";
// import classes from "./Toolbar.module.css";
// // UI Components
// import Logo from "../../UI/Logo/Logo";
// import LongButton from "../../UI/Buttons/LongButton/LongButton";
// // Components
// import DrawerToggle from "../SideDrawer/DrawerToggle/DrawerToggle";
// import NavigationItems from "../NavigationItems/NavigationItems";

// const Toolbar = (props) => {
//   // Redirects to login page
//   const participerHandler = () => {
//     props.history.push("/login");
//   };

//   return (
//     <header className={classes.Toolbar}>
//       <div className={classes.Logo}>
//         <Logo />
//       </div>
//       {!props.currentUser ? (
//         <div className={classes.Participer}>
//           <LongButton clicked={participerHandler}>PARTICIPER</LongButton>
//         </div>
//       ) : (
//         <React.Fragment>
//           <DrawerToggle clicked={props.drawerToggleClicked} />
//           <div className={classes.DesktopOnly}>
//             <NavigationItems />
//           </div>
//         </React.Fragment>
//       )}
//     </header>
//   );
// };

// export default withRouter(Toolbar);
