import { createMuiTheme } from "@material-ui/core/styles";

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#003c71",
    },
    secondary: {
      main: "#E0004D",
    },
  },
  typography: {
    fontFamily: "'PT Sans', sans-serif",
  },
});

export default muiTheme;
