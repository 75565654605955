import React from "react";
import classes from "./Toolbar.module.css";
import * as firebase from "firebase/app";
// MUI
import FaceRoundedIcon from "@material-ui/icons/FaceRounded";
import { IconButton } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// Routing
import { useHistory } from "react-router-dom";

const Toolbar = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => window.location.reload());
    history.push("/");
  };

  const history = useHistory();
  const LogoHandler = () => {
    let path = "/";
    history.push(path);
  };
  return (
    <header className={classes.Toolbar}>
      <div className={classes.Logo} onClick={LogoHandler}>
        <h3 style={{ fontFamily: "Futura Extra Bold" }}>Corehab</h3>
      </div>
      <div className={classes.Avatar}>
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <FaceRoundedIcon style={{ color: "black", fontSize: 40 }} />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => history.push("/profile-setup")}>
            Mon profil
          </MenuItem>
          <MenuItem onClick={handleSignOut}>Se déconnecter</MenuItem>
        </Menu>
      </div>
    </header>
  );
};

export default Toolbar;
