import React from "react";
import classes from "./CircularLoading.module.css";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function CircularIndeterminate() {
  return (
    <div className={classes.CircularLoading}>
      <CircularProgress color="secondary" size={150} />
    </div>
  );
}
