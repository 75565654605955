import React from "react";
import classes from "./Card.module.css";
// MUI
// import { Grid, ButtonBase, IconButton } from "@material-ui/core";
// import BuildRoundedIcon from "@material-ui/icons/BuildRounded";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AddBoxRoundedIcon from "@material-ui/icons/AddBoxRounded";

const Card = (props) => {
  const icon = props.submitted ? (
    <CheckBoxIcon style={{ color: "#E57373", fontSize: "1.5rem" }} />
  ) : (
    <AddBoxRoundedIcon style={{ color: "white", fontSize: "1.5rem" }} />
  );

  const handleCardOpen = () => {
    props.handleOpen(true);
    props.executeScroll();
  };

  return (
    <div
      className={classes.Card}
      style={
        props.type === "concertable"
          ? { backgroundColor: "#e0ecff" }
          : { backgroundColor: "#FFF3E0" }
      }
      onClick={handleCardOpen}
    >
      <div className={classes.Thumbnail}>
        <img
          alt="salon"
          src={require(`../../../../assets/travaux/${props.imgSrc}`)}
        />
      </div>

      <div className={classes.Main}>
        <div className={classes.Titre}>
          <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
            {props.titleStrong}
          </span>
          <span>{props.titleWeak}</span>
        </div>
        <div className={classes.Description}>
          <span> {props.description}</span>
        </div>
        <div className={classes.Tags}>
          {props.tags.map((tag) => (
            <span key={tag}>{tag}</span>
          ))}
        </div>
      </div>

      <div className={classes.Icon}>{icon}</div>
    </div>
  );
};

export default Card;
