import React from "react";
import classes from "./Home.module.css";
// Images
// import BonHomme from "../../assets/images/bonhomme.png";
// Primary Components
// import DescriptionSection from "../../components/HomePage/DescriptionSection/DescriptionSection";
import Blog from "../Blog/Blog";
import ContactForm from "../../components/Travaux/ContactForm/ContactForm";
import Footer from "../../components/Travaux/Footer/Footer";
// UI Componentns
import Separator from "../../components/UI/Separator/Separator";
// import LongButton from "../../components/UI/Buttons/LongButton/LongButton";
// Layout
import Layout from "../../hoc/Layout/Layout";
// MUI
import { Button } from "@material-ui/core";

const Home = (props) => {
  const banValenton = require(`../../assets/homePage/banValentonHaute.png`);
  const GrandesEtapes = require(`../../assets/homePage/Calendar_Block3.svg`);
  const pont = require(`../../assets/homePage/pont.png`);
  const petitEtang2 = require(`../../assets/homePage/petitEtang2.png`);
  const renard = require(`../../assets/homePage/renard.png`);
  const ParticiperHandler = () => {
    props.history.push("/travaux");
  };
  const blogPostHandler = () => {
    props.history.push("/blog/1");
  };

  return (
    <Layout>
      {/* <div className={classes.HeaderImage}>
        <img style={{ width: "100%" }} alt="header" src={illustrationAttente} />
      </div> */}
      <div className={classes.HomeCardBackground}>
        <div className={classes.HomeCardContent}>
          <div className={classes.HeroImage}>
            <img alt="Planning" src={banValenton} />
          </div>

          <div className={classes.Section}>
            <div className={classes.SectionTitle}>
              <span className={classes.Emoji} role="img" aria-label="emoji">
                👪
              </span>
              <h3>Un projet en concertation avec les locataires</h3>
            </div>

            <div className={classes.SectionSubtitle}>
              <p>
                Pour mener à bien ce projet ambitieux de réhabilitation,
                Immobilière 3F a décidé de mettre les locataires à contribution
                dans une démarche de concertation inédite.
              </p>
            </div>
            <div className={classes.EmojiText}>
              <span className={classes.Emoji} role="img" aria-label="emoji">
                👉
              </span>
              <p>
                Durant les semaines à venir, vous pourrez choisir et
                personnaliser certains travaux prévus dans le projet de
                réaménagement des espaces extérieurs grâce à l’application
                <strong> PetitEtang.corehab.fr</strong>. Cette application vous
                permettra par ailleurs de vous renseigner sur l’ensemble des
                travaux qui auront lieux dans vos espaces extérieurs. Pour
                commencer, cliquez sur{" "}
                <span style={{ fontWeight: "bold", color: "#E0004D" }}>
                  Participer
                </span>
                .
              </p>
            </div>
            {/* <div className={classes.ParticiperButton}>
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                onClick={ParticiperHandler}
              >
                je participe
              </Button>
            </div> */}
            <div className={classes.EmojiText} style={{ marginTop: "1.5em" }}>
              <span className={classes.Emoji} role="img" aria-label="emoji">
                👉
              </span>
              <p>
                <strong>
                  Une thématique a été choisie pour cette concertation : vos
                  espaces extérieurs.
                </strong>
                <br />
                Dans cette thématique, vous pouvez donner votre avis sur les
                grandes orientations d’aménagement des espaces extérieurs. Les
                résultats des participations guideront les architectes et
                Immobilière 3F dans leurs choix de travaux.
              </p>
            </div>

            <div className={classes.HighlightNote}>
              <span></span>
              <p>
                Plus que jamais, votre avis compte et nous vous attendons
                nombreux sur la plateforme PetitEtang.corehab.fr !
              </p>
            </div>

            <div className={classes.EmojiText}>
              <span className={classes.Emoji} role="img" aria-label="emoji">
                ❓
              </span>
              <p style={{ marginLeft: " .8em" }}>
                <strong>
                  Question: Moi, ou un autre locataire de la résidence ne
                  possède ni ordinateur, ni tablette ni smartphone pour se
                  connecter sur le site PetitEtang.corehab.fr, puis-je ou
                  peut-il tout de même donner son avis et se renseigner sur les
                  travaux de réhabilitation ?
                </strong>
                <br />
                <br />
                Bien sûr ! Une permanence sera organisée dans la première
                quinzaine de Septembre 2020. Elle fournira à tous les locataires
                qui en ont besoin le matériel nécessaire et l’accompagnement
                pour se connecter à l’application et participer. Un
                questionnaire en papier est également disponible auprès de votre
                gardien.
                <br />
              </p>
            </div>
            {/* <div
          className={classes.Illustration}
          style={{
            backgroundImage: `url(${buildingEntrance})`,
          }}
        ></div> */}
          </div>

          <div className={classes.Section} style={{ marginTop: "2em" }}>
            <div className={classes.SectionTitle}>
              <span className={classes.Emoji} role="img" aria-label="emoji">
                🦺
              </span>
              <h3>Le projet de requalification d’un site d’exception</h3>
            </div>

            <div className={classes.SectionSubtitle}>
              <p>
                Depuis Janvier 2020 avec l’aide de l’union européenne et la
                région ile de France , Immobilière 3F a initié d’importants
                travaux de rénovation thermique et de requalification des
                espaces verts de votre résidence.
              </p>
            </div>
            <div className={classes.EmojiText}>
              <span className={classes.Emoji} role="img" aria-label="emoji">
                👉
              </span>
              <p>
                <strong>Le contexte:</strong> Située à la fois dans le
                centre-ville ancien de Valenton et dans un espace boisé
                exceptionnel, la résidence du Petit Étang comprend{" "}
                <strong>380 logements</strong> répartis sur neuf immeubles
                construits en 1960. Elle profite d’un contact immédiat avec{" "}
                <strong>de grands parcs</strong> et plusieurs espaces de{" "}
                <strong>nature remarquable</strong> tels que la forêt de Notre
                Dame.
                <br />
                <br /> Cette proximité est intéressante pour la faune et la
                flore qui peuvent se propager grâce aux alignements d’arbres et
                aux espaces verts{" "}
                <strong>
                  comme celui du Petit étang.
                  <br />
                  <br />
                  En bordure Est, de la résidence
                </strong>
                , le petit bois constitue un véritable refuge pour les animaux
                des jardins. <strong>Un renard</strong> y a par exemple été
                observé cette année. En périphérie sud, le bassin de rétention
                clôturé présente un potentiel d’accueil pour les oiseaux ou les
                libellules.
              </p>
            </div>
          </div>
          <div
            className={classes.Section}
            style={{ marginTop: "2em", marginLeft: "0", marginRight: "0" }}
          >
            <div className={classes.CarouselSection}>
              <div
                className={classes.CarouselItem}
                style={{
                  backgroundImage: `url(${pont})`,
                  backgroundPosition: "right",
                }}
              ></div>
              <div
                className={classes.CarouselItem}
                style={{
                  backgroundImage: `url(${petitEtang2})`,
                  backgroundPosition: "right",
                }}
              ></div>

              <div
                className={classes.CarouselItem}
                style={{
                  backgroundImage: `url(${renard})`,
                  backgroundPosition: "right",
                }}
              ></div>
            </div>
          </div>

          {/* <div
              className={classes.Illustration}
              style={{
                backgroundImage: `url(${renard})`,
                backgroundPosition: "right",
              }}
            ></div> */}
          <div className={classes.Section} style={{ marginTop: "2em" }}>
            <div
              className={classes.EmojiText}
              style={{ marginBottom: "1.5em" }}
            >
              <span className={classes.Emoji} role="img" aria-label="emoji">
                👉
              </span>
              <p>
                <strong>L’ambition du projet:</strong> Au programme des travaux
                qui sont en cours de réalisation figurent la rénovation
                thermique complète des neuf bâtiments, désamiantage des façades,
                traitement thermique des façades et de la couverture,
                remplacement des menuiseries extérieurs et des volets roulant,
                mise en place d’une ventilation mécanique performante.
              </p>
            </div>

            <div
              className={classes.EmojiText}
              style={{ marginBottom: "1.5em" }}
            >
              <span className={classes.Emoji} role="img" aria-label="emoji">
                🖼️
              </span>
              <p>
                <strong>Les espaces extérieurs:</strong> il est prévu de
                réaménager ces espaces pour vous permettre de profiter davantage
                du cadre exceptionnel de la résidence. L’ensemble de ces travaux
                seront <strong>en concertation</strong> avec vous et vise le
                label <strong>Biodivercity</strong>.
              </p>
            </div>

            <div
              className={classes.EmojiText}
              style={{ marginBottom: "1.5em" }}
            >
              <span className={classes.Emoji} role="img" aria-label="emoji">
                ♻️
              </span>
              <p>
                <strong>L’objectif énergétique -</strong> Immobilière 3f vise à
                la suite de ces travaux deux labels :
                <div
                  className={classes.EmojiText}
                  style={{ marginBottom: "1.5em", marginTop: "1.5em" }}
                >
                  <span
                    className={classes.Emoji}
                    role="img"
                    aria-label="emoji"
                    style={{ fontSize: "2.5rem" }}
                  >
                    🏆
                  </span>
                  <p>
                    <strong>
                      L’étiquette B du label Effinergie Rénovation :
                    </strong>{" "}
                    permet de garantir la qualité des travaux de rénovation dans
                    votre résidence en matière de confort et de consommation
                    d’énérgie. À la suite des travaux, votre logement sera
                    confortable et très économe en énergie.
                  </p>
                </div>
                <div
                  className={classes.EmojiText}
                  style={{ marginBottom: "1.5em", marginTop: "1.5em" }}
                >
                  <span
                    className={classes.Emoji}
                    role="img"
                    aria-label="emoji"
                    style={{ fontSize: "2.5rem" }}
                  >
                    🏆
                  </span>
                  <p>
                    <strong>
                      Le label Biodivercity pour les espaces extérieurs :
                    </strong>{" "}
                    permet de garantir la qualité d’ambiance des espaces
                    extérieurs et l’impact de ces espaces sur votre bien etre au
                    quotidien.
                  </p>
                </div>
              </p>
            </div>

            <div className={classes.HighlightNote}>
              <span></span>
              <p>
                3 grands axes sont prévus pour transformer vos espaces
                extérieurs :
              </p>
            </div>

            <div
              className={classes.EmojiText}
              style={{ marginBottom: "1.5em" }}
            >
              <span className={classes.Emoji} role="img" aria-label="emoji">
                🅿️
              </span>
              <p>
                <strong>La desserte:</strong> le projet prévoit de réorganiser
                l’offre de stationnement actuelle en l’augmentant de 46 places,
                de moderniser les voies de desserte et les cheminements piétons.
              </p>
            </div>

            <div
              className={classes.EmojiText}
              style={{ marginBottom: "1.5em" }}
            >
              <span className={classes.Emoji} role="img" aria-label="emoji">
                🌲
              </span>
              <p>
                <strong>Les espaces verts:</strong> le projet prévoit de de
                soigner les arbres et les pelouses existantes, de replanter tous
                les pieds d’immeuble et d’assurer la gestion durable des eaux de
                pluie.
              </p>
            </div>

            <div
              className={classes.EmojiText}
              style={{ marginBottom: "1.5em" }}
            >
              <span className={classes.Emoji} role="img" aria-label="emoji">
                🖼️
              </span>
              <p>
                <strong>L’ étang:</strong> Tout en restant clos, l’étang qui
                donne son nom à la résidence sera également réhabilité avec une
                vocation environnementale.
              </p>
            </div>
          </div>

          <Separator />

          <div className={classes.Section} style={{ marginTop: "1.5em" }}>
            <div className={classes.SectionTitle}>
              <span className={classes.Emoji} role="img" aria-label="emoji">
                📰
              </span>
              <h3>Les dernières informations</h3>
            </div>

            <div className={classes.SectionSubtitle}>
              <p>
                Vous trouverez ici toutes les dernières actualités liées au
                projet et à la concertation locataires.
              </p>
            </div>

            <Blog blogPostHandler={blogPostHandler} />
          </div>
          <div className={classes.Section}>
            <div className={classes.SectionTitle}>
              <span className={classes.Emoji} role="img" aria-label="emoji">
                📅
              </span>
              <h3 style={{ fontSize: "1.2rem" }}>
                Les grandes étapes prévisionnelles du projet de réhabilitation
                et de réaménagements des espaces extérieurs
              </h3>
            </div>

            <div className={classes.SectionSubtitle}>
              <p>
                Trouvez ici toutes les étapes du projet de réhabilitation. Nous
                mettrons à jour régulièrement ce calendrier pour mieux vous
                informer.
              </p>
            </div>

            <div className={classes.GrandesEtapes}>
              <img alt="Planning" src={GrandesEtapes} />
            </div>
          </div>
          <Separator />
          <div className={classes.ContactForm}>
            <ContactForm />
          </div>
        </div>
      </div>

      <div className={classes.Footer}>
        <Footer />
      </div>
    </Layout>
  );
};

export default Home;
