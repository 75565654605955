import React from "react";
import classes from "./CardModal.module.css";

const modal = (props) => {
  return (
    <React.Fragment>
      <div
        style={{
          transform: props.show ? "translateY(0)" : "translateY(-100vh)",
          opacity: props.show ? "1" : "0",
        }}
        className={classes.CardModal}
      >
        {props.children}
      </div>
    </React.Fragment>
  );
};

export default modal;
