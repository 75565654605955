import React from "react";
import classes from "../ResultsConcertableLayout/ResultsConcertableLayout.module.css";
// MUI
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import { TextField, IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";

const concertableQuestion = ({ question, handleChange, cardID }) => {
  return (
    <React.Fragment>
      <div className={classes.Questions}>
        <div className={classes.QuestionTitle}>
          <IconButton style={{ margin: 0, padding: 0 }}>
            <HelpOutlineRoundedIcon
              style={{ fontSize: 40, color: "#E0004D" }}
            />
          </IconButton>
          <h4>{question.title}</h4>
        </div>

        {question.options.map((option) => {
          return (
            <div className={classes.QuestionEnsemble} key={option.value}>
              <p className={classes.QuestionPercentage}>
                {((option.results * 100) / question.totResults).toFixed(0)}% (
                {option.results})
              </p>
              <p
                className={
                  option.value === question.winner
                    ? classes.Winner
                    : classes.QuestionSentence
                }
              >
                {option.label}
              </p>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default concertableQuestion;
