import React from "react";
import classes from "./BlogCard.module.css";
// Images
import pont from "../../../assets/homePage/pont.png";
// UI Components
import LongButton from "../../UI/Buttons/LongButton/LongButton";
import { Button } from "@material-ui/core";

const BlogCard = (props) => {
  return (
    <div className={classes.BlogCard}>
      <div
        className={classes.BlogCardImage}
        style={{
          backgroundImage: `url(${pont})`,
          backgroundPosition: "right",
        }}
      ></div>
      <div className={classes.BlogCardDate}>
        <p>Le 23 juin 2020</p>
      </div>
      <div className={classes.BlogCardContent}>
        <h3 className={classes.BlogCardTitle}>
          La résidence du petit étang, un patrimoine naturel exclusif
        </h3>
        <p style={{ marginTop: "1em" }}>
          Située dans le sud-est de la région parisienne, la commune de Valenton
          offre un cadre de vie urbain fourni en parcs et jardins où l’eau tient
          une place de choix...
        </p>
      </div>
      <div className={classes.BlogCardButton}>
        <Button color="secondary" size="small" onClick={props.blogPostHandler}>
          <strong>en savoir plus</strong>
        </Button>
      </div>
    </div>
  );
};

export default BlogCard;
