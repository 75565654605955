import { db } from "../config/firebase";

// Adds the locataire profile setup info
export const addLocInfo = (profileSetupData, locataireID) => {
  const {
    name,
    lastName,
    birthYear,
    flatSize,
    numCars,
    numMotors,
    numBikes,
  } = profileSetupData;
  const locInfo = {
    fullName: `${name.value} ${lastName.value}`,
    birthYear: birthYear.value,
    flatSize: flatSize.value,
    numCars: numCars.value,
    numMotors: numMotors.value,
    numBikes: numBikes.value,
  };

  db.collection("locataires")
    .doc(locataireID)
    .update(locInfo)
    .then((resp) => {
      console.log("Doc successfully updated");
    })
    .catch((err) => {
      console.log("Error while updating doc :", err);
    });
};
