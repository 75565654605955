import { db } from "../config/firebase";
import moment from "moment";

// Filter Validation
// export const testFilter = (filters, tags) => {
//   // filters array, tags array
//   let isValid = false;
//   let filtreBuffer = false;

//   tags.map((tag) => {
//     filtreBuffer = filtre.includes(tag);
//     isValid = isValid || filtreBuffer;
//   });

//   return isValid;
// };

// Upload answers to firebase
export const uploadAnswer = (locataireID, cardID, answers, dispatch) => {
  const answer = {
    locataireID,
    cardID,
    answers,
    date: moment().format(),
  };

  db.collection("resultats")
    .doc(cardID + "+" + locataireID)
    .set(answer)
    .then((resp) => {
      console.log("Answer updated successfully :", resp);
      dispatch({ type: "SET_HIDECARD" });
      dispatch({ type: "SET_SUBMITTED", cardID });
    })
    .catch((err) => console.log("Error while updating answer :", err));
};

// Load answers from firebase
export const loadAnswers = (locataireID, dispatch) => {
  db.collection("resultats")
    .where("locataireID", "==", locataireID)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        Object.keys(doc.data().answers).map((question) => {
          if (doc.data().cardID[0] === "c") {
            if (question !== "comment") {
              dispatch({
                type: "LOAD_ANSWERS_C",
                cardID: doc.data().cardID,
                questionID: question,
                value: doc.data().answers[question].value,
                comment: doc.data().answers.comment,
              });
              // Set the image
              dispatch({ type: "UPDATE_IMAGE", cardID: doc.data().cardID });
            }
          } else if (doc.data().cardID[0] === "n") {
            dispatch({
              type: "LOAD_ANSWERS_NC",
              cardID: doc.data().cardID,
              comment: doc.data().answers.comment,
            });
          }
        });
      });
    })
    .catch((err) => console.log("Error getting document: ", err));
};
